<template>
  <div class="home">
    <!-- 上部分 -->
    <div class="above">
      <!-- 上左部分 -->
      <div class="above-left">
        <div class="above-left-one com">
          <span>签约医院：{{hospitalCount}}家</span>
          <span>合规司机：{{driverCount}}名</span>
          <span>合规转运车：{{vehicalCount}}辆</span>
        </div>
        <div class="above-left-two com">
          <span>当月订单（单）：{{orderMonthCount}}</span>
          <span>当月交易金额（元）：{{orderMonthMoney}}</span>
        </div>
        <div class="above-left-three com">
          <span>昨日运营车辆：{{yesterdayVehicalCount}} 台</span>
          <span>昨日运营订单：{{yesterdayOrderCount}}</span>
          <span style="color: #fb1b2c">较前一天：{{yesterdayOrderCountPercent}}</span>
          <span>昨日运营订单金额：{{yesterdayOrderMoney}}</span>
          <span style="color: #e87755">较前一天：{{yesterdayOrderMoneyPercent}}</span>
        </div>
      </div>
      <!-- 上右部分 -->
      <div class="above-right">
        <div class="above-right-top">
          <div class="above-right-top-one cn">
            <span>接入医院</span>
            <span>{{hospitalCount}}</span>
          </div>
          <div class="above-right-top-two cn">
            <span>接入车辆</span>
            <span>{{vehicalCount}}</span>
          </div>
          <div class="above-right-top-three cn">
            <span>接入驾驶员</span>
            <span>{{driverCount}}</span>
          </div>
          <div class="above-right-top-four cn">
            <span>订单数</span>
            <span>{{orderTotalCount}}</span>
          </div>
        </div>
        <div class="above-right-bottom">
          <div class="above-right-bottom-one" id="bottom-one"></div>
          <div class="above-right-bottom-two" id="bottom-two"></div>
          <div class="above-right-bottom-three" id="bottom-three"></div>
        </div>
      </div>
    </div>
    <!-- 下部分 -->
    <div class="below">
      <div class="below-one" id="below-one"></div>
      <div class="below-two" id="below-two"></div>
    </div>
  </div>
</template>

<script>
var echarts = require('echarts')
export default {
  name: 'home',
  components: {},
  data () {
    return {
      complainTime: [], // 投诉时间
      complainCount: [], // 投诉次数
      peopleCount: [], // 上线客户数量
      peopleTime: [], // 上线客户时间
      payOrderCount: [], // 下单客户数量
      transportTypeMap: {}, // 转运车类型占比
      orderCount: [], // 近半月订单数
      orderDate: [], // 近半月日期
      orderMoneyNum: [], // 近半月收入
      orderMoneyDate: [], // 近半月订单金额日期
      hospitalCount: '', // 签约医院
      driverCount: '', // 合规司机
      vehicalCount: '', // 合规车辆
      orderMonthCount: '', // 当月订单
      orderMonthMoney: '', // 当月交易金额
      yesterdayVehicalCount: '', // 昨日运营车辆
      yesterdayOrderCount: '', // 昨日运营订单
      yesterdayOrderMoney: '',
      yesterdayOrderMoneyPercent: '', // 昨日运营订单金额比
      yesterdayOrderCountPercent: '', // 昨日运营订单数量比
      orderTotalCount: '' // 总订单数
      //   statisticalInterval: ''

    }
  },
  beforeDestroy () {
    // clearInterval(this.statisticalInterval)
  },
  mounted () {
    this.getStatisticalForWebservice()
    // var statisticalInterval = setInterval(() => {
    this.getStatisticalForWebservice()
    console.log('机构')
    // }, 15000)
    // this.statisticalInterval = statisticalInterval
  },
  methods: {
    async getStatisticalForWebservice () {
      var that = this
      const { data: res } = await this.$http.get('/userManageServer/hospital/selectDecimalCount', {})
      // console.log('统计数据' + JSON.stringify(res.data))
      // 投诉
      that.complainTime = res.data.orderComplain.complainTime.split(',')
      that.complainCount = res.data.orderComplain.complainCount.split(',')
      // 用户下单及在线分析
      that.peopleCount = res.data.orderPeople.peopleCount.split(',')
      that.peopleTime = res.data.orderPeople.peopleTime.split(',')
      that.payOrderCount = res.data.payOrderPeople.payOrderCount.split(',')

      // 转运车类型占比
      that.transportTypeMap = res.data.transportType
      // 近半月订单数
      that.orderCount = res.data.orderNum.orderCount.split(',')
      that.orderDate = res.data.orderNum.orderDate.split(',')
      // 近半月订单金额
      that.orderMoneyNum = res.data.orderMoneyNum.orderCount.split(',')
      that.orderMoneyDate = res.data.orderMoneyNum.orderDate.split(',')
      // 签约医院
      that.hospitalCount = res.data.hospitalCount
      // 合规司机
      that.driverCount = res.data.driverCount
      // 合规车辆
      that.vehicalCount = res.data.vehicalCount
      // 当月订单
      that.orderMonthCount = res.data.orderMonthCount
      // 当月交易金额
      that.orderMonthMoney = res.data.orderMonthMoney
      // 昨日运营车辆
      that.yesterdayVehicalCount = res.data.yesterdayVehicalCount
      // 昨日运营订单金额
      that.yesterdayOrderMoney = res.data.yesterdayOrderMoney
      // 昨日运营车辆
      that.yesterdayOrderCount = res.data.yesterdayOrderCount
      // 昨日运营金额比
      that.yesterdayOrderMoneyPercent = res.data.yesterdayOrderMoneyPercent
      // 昨日运营订单比
      that.yesterdayOrderCountPercent = res.data.yesterdayOrderCountPercent
      // console.log(res.data);
      // 总订单数
      that.orderTotalCount = res.data.orderCount
      that.initCharsData()
    },
    initCharsData () {
      this.initChartOne()
      this.initChartTwo()
      this.initChartThree()
      this.initChartFour()
      this.initChartFive()
    },
    initChartOne () {
      var that = this
      var myChart = echarts.init(document.getElementById('bottom-one'))
      myChart.setOption({
        title: {
          text: '用户量分析汇总',
          textStyle: {
            fontSize: 18,
            color: '#0097FE',
            fontWeight: 'bold',
            height: 20
          }
        },
        tooltip: {},
        legend: {
          data: ['上线客户数量', '下单客户数量'],
          orient: 'vertical',
          right: '7%',
          inactiveColor: '#ccc',
          textStyle: {
            // color: '#eee'
          }
        },
        xAxis: {
          type: 'category',
          data: that.peopleTime,
          axisTick: {
            alignWithLabel: true,
            color: '#ccc'
            // show: false
          },
          axisLabel: {
            color: '#0097FE',
            rotate: 40
          },
          axisLine: {
            show: false,
            lineStyle: {
              // color: '#fffff'
            }
          }
        },
        yAxis: {
          axisLine: { show: false },
          //   max: 1,
          //   min: 0,
          axisLabel: {
            color: '#0097FEFF',
            formatter: function (v, i) {
              return v
            }
          },
          splitLine: {
            lineStyle: {
              color: '#ffffff'
            }
          }
        },
        grid: {
          left: 50
        },
        series: [{
          name: '上线客户数量',
          type: 'line',
          data: that.peopleCount,
          smooth: true,
          areaStyle: {
            normal: {
              color: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [{
                  offset: 0,
                  color: '#bee6fd'
                }, {
                  offset: 0.7,
                  color: '#97d1fe'
                }],
                gobalCoord: false
              }
            }
          },
          itemStyle: {
            normal: {
              color: '#2b80ff',
              lineStyle: {
                color: '#0097fe'
              }
            }
          },
          symbolSize: 5,
          symbol: 'circle'
        }, {
          name: '下单客户数量',
          type: 'line',
          data: that.payOrderCount,
          itemStyle: {
            normal: {
              color: '#04cdf4',
              lineStyle: {
                color: '#04cdf4'
              }
            }
          },
          symbolSize: 5,
          symbol: 'circle'
        }]
      })
    },
    initChartTwo () {
      var that = this
      var myChart = echarts.init(document.getElementById('bottom-two'))
      myChart.setOption({
        title: {
          text: '用户投诉统计',
          textStyle: {
            fontSize: 18,
            color: '#0097FE',
            fontWeight: 'bold',
            height: 20
          }
        },
        tooltip: {},
        legend: {
          show: false
          // data: ['上线客户数量', '下单客户数量']
        },
        xAxis: {
          type: 'category',
          data: that.complainTime,
          axisTick: {
            alignWithLabel: true,
            color: '#ccc'
          },
          axisLabel: {
            color: '#0097FE',
            rotate: 40
          },
          axisLine: {
            show: false,
            lineStyle: {}
          }
        },
        yAxis: {
          axisLine: { show: false },
          //   max: 12,
          min: 10,
          axisLabel: {
            color: '#0097FEFF'
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [{
          name: '用户投诉量',
          type: 'bar',
          data: that.complainCount,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1, [
                { offset: 1, color: '#438cf5' },
                { offset: 0, color: '#5fc3ff' }
              ]
            ),
            borderRadius: [4, 4, 0, 0]
          },
          hoverOffset: 10,
          barMaxWidth: 8
          // symbolSize: 5
          // symbol: 'circle'
        }]
      })
    },
    initChartThree () {
      var that = this
      var myChart = echarts.init(document.getElementById('bottom-three'))
      myChart.setOption({
        title: {
          // text: '用户投诉统计'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '30%',
          right: 50,
          orient: 'vertical',
          icon: 'circle'
        },
        series: [{
          // name: '销量',
          type: 'pie',
          radius: '70%',
          center: ['40%', '50%'],
          // 转运类型(transportType1普通护送transportType2高级护送transportType3护理护送transportType4监护护送
          data: [
            {
              value: that.transportTypeMap.transportType4,
              name: '监护护送',
              itemStyle:
              {
                color: '#0494f9'
              },
              label: {
                color: '#0494f9'
              }
            },
            {
              value: that.transportTypeMap.transportType3,
              name: '护理护送',
              itemStyle:
              {
                color: '#6ca8ff'
              },
              label: {
                color: '#6ca8ff'
              }
            },
            {
              value: that.transportTypeMap.transportType2,
              name: '高级护送',
              itemStyle:
              {
                color: '#5bb7fe'
              },
              label: {
                color: '#5bb7fe'
              }
            },
            {
              value: that.transportTypeMap.transportType1,
              name: '普通护送',
              itemStyle:
              {
                color: '#4ecdfb'
              },
              label: {
                color: '#4ecdfb'
              }
            }
          ],
          itemStyle: {
            borderWidth: 2,
            borderColor: '#b8defc'
          }
        }]
      })
    },
    initChartFour () {
      var that = this
      var myChart = echarts.init(document.getElementById('below-one'))
      myChart.setOption({
        title: {
          text: '最近14天运营次数日报趋势图',
          textStyle: {
            fontSize: 18,
            color: '#0097FE',
            fontWeight: 'bold',
            height: 20
          }
        },
        grid: {
          x: '3%'
        },
        tooltip: {},
        legend: {
          show: false
          // data: ['上线客户数量', '下单客户数量']
        },
        xAxis: {
          type: 'category',
          data: that.orderDate,
          axisTick: {
            alignWithLabel: true,
            color: '#ccc'
          },
          axisLabel: {
            color: '#0097FE',
            rotate: 40
          },
          axisLine: {
            show: false,
            lineStyle: {}
          }
        },
        yAxis: {
          axisLine: { show: false },
          //   max: 12,
          min: 10,
          axisLabel: {
            color: '#0097FEFF'
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [{
          name: '运营次数',
          type: 'bar',
          // center: [0, '50%'],
          data: that.orderCount,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1, [
                { offset: 1, color: '#438cf5' },
                { offset: 0, color: '#5fc3ff' }
              ]
            ),
            borderRadius: [4, 4, 0, 0]
          },
          hoverOffset: 10,
          barMaxWidth: 8
          // symbolSize: 5
          // symbol: 'circle'
        }]
      })
    },
    initChartFive () {
      var that = this
      var myChart = echarts.init(document.getElementById('below-two'))
      myChart.setOption({
        title: {
          text: '最近14天运营收入趋势图：',
          textStyle: {
            fontSize: 18,
            color: '#0097FE',
            fontWeight: 'bold',
            height: 20
          }
        },
        grid: {
          x: '3%'
        },
        tooltip: {},
        legend: {
          show: false
          // data: ['上线客户数量', '下单客户数量']
        },
        xAxis: {
          type: 'category',
          data: that.orderMoneyDate,
          axisTick: {
            alignWithLabel: true,
            color: '#ccc'
          },
          axisLabel: {
            color: '#0097FE',
            rotate: 40
          },
          axisLine: {
            show: false,
            lineStyle: {}
          }
        },
        yAxis: {
          axisLine: { show: false },
          //   max: 12,
          min: 10,
          axisLabel: {
            color: '#0097FEFF'
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [{
          name: '运营次数',
          type: 'bar',
          // center: [0, '50%'],
          data: that.orderMoneyNum,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1, [
                { offset: 1, color: '#438cf5' },
                { offset: 0, color: '#5fc3ff' }
              ]
            ),
            borderRadius: [4, 4, 0, 0]
          },
          hoverOffset: 10,
          barMaxWidth: 8
          // symbolSize: 5
          // symbol: 'circle'
        }]
      })
    }

  }
}
</script>
<style lang="less" scoped>
.home {
  padding: 20px 30px 11px;
  color: #0097fe;
  height: 100%;
  width:100%;

  // margin-top: 1.75rem;
}
//公共样式
.com {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #d4f0fd;
  padding-left: 24px;
  border: 1px solid #a4dafc;

  span:nth-child(n + 2) {
    margin-top: 21px;
  }
}
.cn {
  width: 310px;
  height: 166px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding-left: 44px;
  font-size: 24px;
  span:nth-child(2) {
    font-size: 50px;
    font-weight: 400;
    width: 219px;
    border-top: 1px solid #fff;
    margin-top: 13px;
  }
}
// 上部分
.above {
  display: flex;
  width: 100%;
  height: calc(100% - 4rem);
  // 上左部分
  .above-left {
    width: 400px;
    margin-right: 47px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;;
    div:nth-child(n + 2) {
      margin-top: 22px;
    }
    .above-left-one {
      flex: 1;
      height: 200px;
    }
    .above-left-two {
      flex: 1;
      height: 120px;
    }
    .above-left-three {
      flex: 1;
      font-size: 14px;
      height: 176px;
      span:nth-child(1) {
        font-size: 18px;
      }
      span:nth-child(n) {
        margin: 0;
      }
      span:nth-child(2n) {
        margin-top: 18px;
      }
    }
  }
  // 上右部分
  .above-right {
    display: flex;
    flex-direction: column;
    width: calc(100% - 5rem);
    .above-right-top {
      flex: 1;
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      div:nth-child(-n + 3) {
        margin-right: 50px;
      }

      .above-right-top-one {
        flex: 1;
        height: 100%;
        background: url('../../fonts/hospital.png') no-repeat;
        background-size: cover;
        :nth-child(2) {
          text-align: center;
        }
      }
      .above-right-top-two {
        flex: 1;
        height: 100%;
        background: url('../../fonts/car.png') no-repeat;
        background-size: cover;
        :nth-child(2) {
          text-align: center;
        }
      }
      .above-right-top-three {
        flex: 1;
        height: 100%;
        background: url('../../fonts/driver.png') no-repeat;
        background-size: cover;
        :nth-child(2) {
          text-align: center;
        }
      }
      .above-right-top-four {
        flex: 1;
        height: 100%;
        background: url('../../fonts/order.png') no-repeat;
        background-size: cover;
        :nth-child(2) {
          text-align: center;
        }
      }
    }
    .above-right-bottom {
      flex: 2;
      display: flex;
      .above-right-bottom-one {
        flex: 1;
        height: 1005;
        background-color: #d4f0fd;
        padding-top: 0.4875rem;
        padding-left: 0.3rem;
        border: 1px solid #a4dafc;
      }
      .above-right-bottom-two {
        flex: 1;
        height: 100%;
        background-color: #d4f0fd;
        margin: 0 42px;
        padding-top: 0.4875rem;
        padding-left: 0.3rem;
        border: 1px solid #a4dafc;
      }
      .above-right-bottom-three {
        flex: 1;
        height: 100%;
      }
    }
  }
}
// 下部分
.below {
  margin-top: 30px;
  height: 300px;
  display: flex;
  .below-one {
    flex: 1;
    background-color: #d4f0fd;
    padding-top: 0.25rem;
    padding-left: 0.2875rem;
    margin-right: 20px;
    border: 1px solid #a4dafc;
  }
  .below-two {
    flex: 1;
    margin-left: 20px;
    padding-top: 0.25rem;
    padding-left: 0.2875rem;
    background-color: #d4f0fd;
    border: 1px solid #a4dafc;
  }
}
</style>
